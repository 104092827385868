<template>
  <div class="tabs">
    <div class="tabs__wrapper">
      <Row class="-m-h-15">
        <slot/>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
};
</script>
